import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import "./../styles/404.css"
import NotFoundImage from "./../images/not-found-image.jpg"

const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    const urlPath = window.location.href
    if (urlPath.indexOf("deeplink") > -1) {
      let queryString = window.location.search.split("?")[1]
      window.location.replace(
        `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.moo&hl=en_IN&referrer=deeplink&${queryString}`
      )
    }
  }
  return (
    <Layout>
      <div className="not-found-container">
        <div className="display-flex-1 center-content">
          <img src={NotFoundImage} className="not-found-image"></img>
          <h1 className="not-found-container-heading">NOT FOUND</h1>
          <p className="not-found-content">
            You just hit a route that doesn&#39;t exist...
          </p>
          <button className="go-home-button">
            {<Link to="/">Go to Home Page</Link>}
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
